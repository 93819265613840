import { Injectable } from '@angular/core';
import { ApiHttpService } from '../../../../core/service/api-http-service';
import { ApiEndpointsService } from '../../../../core/service/api-endpoints.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BookingService {
    public $updateSeatLayoutSubject = new Subject<any>();

    _resetEverything: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private apiHttpService: ApiHttpService, private apiEndpointsService: ApiEndpointsService) { }

    set resetEverything(value: boolean) {
        this._resetEverything.next(value);
    }

    get resetEverything$() {
        return this._resetEverything.asObservable();
    }

    public getBookingInfoByDate(date) {
        const url: string = this.apiEndpointsService.createUrl(`schedule-dates/details-by-date?date=${date}`);
        return this.apiHttpService.get(url);
    }
    public getTotalSeatList(body) {
        const url: string = this.apiEndpointsService.createUrl(`floors/floor-detail-with-seats?scheduleId=${body.scheduleId}&floorId=${body.floorId}&date=${body.date}`);
        return this.apiHttpService.get(url);
    }
    public reservedSeat(body) {
        const url: string = this.apiEndpointsService.createUrl(`reservation`);
        return this.apiHttpService.post(url, body);
    }
    public createOrder(body) {
        const url: string = this.apiEndpointsService.createUrl(`orders/create-order`);
        return this.apiHttpService.post(url, body);
    }

    public otpVarification(body) {
        const url: string = this.apiEndpointsService.createUrl(`web/auth/otp-verify`);
        return this.apiHttpService.post(url, body);
    }
    public cancelReservation(body) {
        const url: string = this.apiEndpointsService.createUrl(`reservation/delete-by-seat-ids`);
        return this.apiHttpService.post(url, body);
    }

    public getPaymentLink(orderId) {
        const url: string = this.apiEndpointsService.createUrl(`orders/pay-now`);
        return this.apiHttpService.post(url, { orderId });
    }

    public getOrderDetails(orderId) {
        const url: string = this.apiEndpointsService.createUrl(`orders/by-orderid?orderId=${orderId}`);
        return this.apiHttpService.get(url);
    }

}
